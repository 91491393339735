:root {
  --color-internaten: #009FDF;
}

[data-theme='meander-internaten'] {
  --color-primary-900: #00202D;
  --color-primary-800: #004059;
  --color-primary-700: #005F86;
  --color-primary-600: #007FB2;
  --color-primary-500: #009FDF;
  --color-primary-400: #33B2E5;
  --color-primary-300: #66C5EC;
  --color-primary-200: #99D9F2;
  --color-primary-100: #CCECF9;
  --color-primary-50: #E5F5FC;

  --color-cta-900: #5E012E;
  --color-cta-800: #800843;
  --color-cta-700: #A21359;
  --color-cta-600: #C42271;
  --color-cta-500: #E8308A;
  --color-cta-400: #FF47A1;
  --color-cta-300: #FF71B6;
  --color-cta-200: #FF9ACB;
  --color-cta-100: #FFC3E0;
  --color-cta-50: #FFECF5;

  --color-shade-900: #303133;
  --color-shade-800: #3F4042;
  --color-shade-700: #5B5E60;
  --color-shade-600: #787B7F;
  --color-shade-500: #95999D;
  --color-shade-400: #AAADB1;
  --color-shade-300: #BFC1C4;
  --color-shade-200: #D5D6D8;
  --color-shade-100: #EAEAEB;
  --color-shade-50: #F4F5F5;

  --color-success-900: #0B2B1A;
  --color-success-800: #165634;
  --color-success-700: #22804D;
  --color-success-600: #2DAB67;
  --color-success-500: #38D681;
  --color-success-400: #60DE9A;
  --color-success-300: #88E6B3;
  --color-success-200: #AFEFCD;
  --color-success-100: #D7F7E6;
  --color-success-50: #EBFBF2;

  --color-warning-900: #312404;
  --color-warning-800: #624907;
  --color-warning-700: #936D0B;
  --color-warning-600: #C4920E;
  --color-warning-500: #F5B612;
  --color-warning-400: #F7C541;
  --color-warning-300: #F9D371;
  --color-warning-200: #FBE2A0;
  --color-warning-100: #FDF0D0;
  --color-warning-50: #FEF8E7;

  --color-error-900: #2E0A10;
  --color-error-800: #5C1421;
  --color-error-700: #8B1E31;
  --color-error-600: #B92842;
  --color-error-500: #E73252;
  --color-error-400: #EC5B75;
  --color-error-300: #F18497;
  --color-error-200: #F5ADBA;
  --color-error-100: #FAD6DC;
  --color-error-50: #FDEBEE;
}
