html {
  scroll-behavior: smooth;
}

a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none !important;
}

#CookiebotWidget .CookiebotWidget-body .CookiebotWidget-main-logo {
  display: none !important;
}
