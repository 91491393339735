:root {
  --color-kinderopvang: #4C8C2B;
}

[data-theme='kinderopvang'] {
  --color-primary-900: #24340E;
  --color-primary-800: #334A10;
  --color-primary-700: #4C7017;
  --color-primary-600: #66951F;
  --color-primary-500: #4C8C2B;
  --color-primary-400: #99C852;
  --color-primary-300: #CEDC00;
  --color-primary-200: #CCE3A9;
  --color-primary-100: #E5F1D4;
  --color-primary-50: #F2F8E9;

  --color-cta-900: #5E012E;
  --color-cta-800: #800843;
  --color-cta-700: #A21359;
  --color-cta-600: #C42271;
  --color-cta-500: #E8308A;
  --color-cta-400: #FF47A1;
  --color-cta-300: #FF71B6;
  --color-cta-200: #FF9ACB;
  --color-cta-100: #FFC3E0;
  --color-cta-50: #FFECF5;

  --color-shade-900: #212524;
  --color-shade-800: #3D4442;
  --color-shade-700: #586461;
  --color-shade-600: #74837F;
  --color-shade-500: #90A29E;
  --color-shade-400: #A6B5B1;
  --color-shade-300: #BCC7C5;
  --color-shade-200: #D3DAD8;
  --color-shade-100: #E9ECEC;
  --color-shade-50: #F4F6F5;

  --color-success-900: #0B2B1A;
  --color-success-800: #165634;
  --color-success-700: #22804D;
  --color-success-600: #2DAB67;
  --color-success-500: #38D681;
  --color-success-400: #60DE9A;
  --color-success-300: #88E6B3;
  --color-success-200: #AFEFCD;
  --color-success-100: #D7F7E6;
  --color-success-50: #EBFBF2;

  --color-warning-900: #312404;
  --color-warning-800: #624907;
  --color-warning-700: #936D0B;
  --color-warning-600: #C4920E;
  --color-warning-500: #F5B612;
  --color-warning-400: #F7C541;
  --color-warning-300: #F9D371;
  --color-warning-200: #FBE2A0;
  --color-warning-100: #FDF0D0;
  --color-warning-50: #FEF8E7;

  --color-error-900: #2E0A10;
  --color-error-800: #5C1421;
  --color-error-700: #8B1E31;
  --color-error-600: #B92842;
  --color-error-500: #E73252;
  --color-error-400: #EC5B75;
  --color-error-300: #F18497;
  --color-error-200: #F5ADBA;
  --color-error-100: #FAD6DC;
  --color-error-50: #FDEBEE;
}
