@layer utilities {
  /* Set heading font family */
  .text-heading-3xl,
  .text-heading-2xl,
  .text-heading-xl,
  .text-heading-lg,
  .text-heading-md,
  .text-heading-sm,
  .text-heading-xs {
    @apply font-heading font-semibold; /* Currently has no different weights */
  }

  /* Set body font family */
  .text-body-xl,
  .text-body-lg,
  .text-body-md,
  .text-body-sm {
    @apply font-default font-normal; /* Used as default overruled by modifier below */
  }

  .text-body-xl--semibold,
  .text-body-lg--semibold,
  .text-body-md--semibold,
  .text-body-sm--semibold {
    @apply font-semibold;
  }
}
